import appImages from "components/appImages";

export const AdminRole = 1;
export const CompanyOwnerRole = 2;
export const SupervisorRole = 3;
export const FloorManagerRole = 4;
export const UserDateFormat = "MMM DD, YYYY";
export const DataTablePerPageRecord = 10;


export const NavItems = [
  {
    name: "dashboard",
    isSidebar: true,
    icon: appImages.DashboardIcon,
    activeIcon: appImages.ActiveDashboardIcon,
    path: 'dashboard',
    role: [AdminRole, CompanyOwnerRole, SupervisorRole, FloorManagerRole],
  },
  {
    name: "customers",
    isSidebar: true,
    icon: appImages.CustomerIcon,
    activeIcon: appImages.ActiveCustomersIcon,
    path: "customer",
    role: [AdminRole],
  },
  {
    name: "area",
    isSidebar: true,
    icon: appImages.Area,
    activeIcon: appImages.AreaActive,
    path: "area",
    role: [CompanyOwnerRole],
  },
  {
    name: "sites",
    isSidebar: true,
    icon: appImages.SiteIcon,
    activeIcon: appImages.ActiveSite,
    path: "site",
    role: [CompanyOwnerRole, SupervisorRole, FloorManagerRole],
  },
  {
    name: "map",
    isSidebar: true,
    icon: appImages.MapIcon,
    activeIcon: appImages.ActiveMapIcon,
    path: "map",
    role: [AdminRole, CompanyOwnerRole, SupervisorRole, FloorManagerRole],
  },
  {
    name: "supervisor",
    isSidebar: true,
    icon: appImages.SupervisorIcon,
    activeIcon: appImages.ActiveSupervisorIcon,
    path: "supervisor",
    role: [CompanyOwnerRole],
  },
  {
    name: "site_manager",
    isSidebar: true,
    icon: appImages.FloorManagerIcon,
    activeIcon: appImages.FloorManagerActiveIcon,
    path: "floor-manager",
    role: [CompanyOwnerRole, SupervisorRole],
  },
  {
    name: "alarms",
    isSidebar: true,
    icon: appImages.AlertIcon,
    activeIcon: appImages.AlertActiveIcon,
    path: "alarms",
    role: [CompanyOwnerRole, SupervisorRole, FloorManagerRole],
  },
  {
    name: "alert_history",
    isSidebar: true,
    icon: appImages.AlertHistory,
    activeIcon: appImages.AlertHistoryActiveIcon,
    path: "alert-history",
    role: [CompanyOwnerRole, SupervisorRole, FloorManagerRole],
  },
  {
    name: "cctv",
    isSidebar: true,
    icon: appImages.CCTVIcon,
    activeIcon: appImages.CCTVActiveIcon,
    path: "cctv",
    role: [CompanyOwnerRole, SupervisorRole, FloorManagerRole],
  },
  {
    name: "support_tickets",
    isSidebar: true,
    icon: appImages.SupportTicketIcon,
    activeIcon: appImages.SupportTicketActiveIcon,
    path: "support-ticket",
    role: [SupervisorRole, FloorManagerRole],
  },
  {
    name: "my_profile",
    isSidebar: true,
    icon: appImages.ProfileIcon,
    activeIcon: appImages.ActiveProfileIcon,
    path: "profile",
    role: [AdminRole, CompanyOwnerRole, SupervisorRole, FloorManagerRole],
  },
  {
    name: "settings",
    isSidebar: true,
    icon: appImages.SettingIcon,
    activeIcon: appImages.SettingActiveIcon,
    path: "settings",
    role: [CompanyOwnerRole],
  },
];

export const TableHead = {
  id: "",
  name: "",
  author: "",
  released: "",
};

export const TableData = [
  {
    id: "1",
    name: "Ghost in The Wires",
    author: "Kevin Mitnick",
    released: "08/15/2011",
  },
  {
    id: "2",
    name: "Console Wars",
    author: "Blake J. Harris",
    released: "05/13/2014",
  },
  {
    id: "3",
    name: "The Phoenix Project",
    author: "Gene Kim, Kevin Behr, George Spafford",
    released: "12/01/2017",
  },
  {
    id: "3",
    name: "The Phoenix Project",
    author: "Gene Kim, Kevin Behr, George Spafford",
    released: "12/01/2017",
  },
];


export const PermissionsRoleBase = ({ role }: any) => {
  switch (role) {
    case AdminRole:
      return ['view_admin_dashboard', 'view_customer','view_profile', 'view_map']
    case CompanyOwnerRole:
      return ['view_dashboard', 'view_area','view_sites', 'view_map', 'view_supervisor', 'view_floormanager', 'view_alarm', 'view_cctv', 'view_profile']
    case SupervisorRole:
      return ['view_dashboard', 'view_sites','view_map', 'view_alarm', 'view_cctv', 'view_support_ticket', 'view_profile']
    case FloorManagerRole:
      return ['view_dashboard', 'view_sites','view_map', 'view_floormanager', 'view_alarm', 'view_cctv', 'view_support_ticket', 'view_profile']
    default:
      return [];
  }
};