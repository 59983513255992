import spalshImg from "assets/images/splash-img.png";
import loginImg from "assets/images/login-img.png";
import EyeSlash from "assets/images/eye-slash.svg";
import UserIcon from "assets/images/userIcon.svg";
import Eye from "assets/images/eye.svg";
import BrandImg from "assets/images/company_logo.svg";
import DashboardIcon from "assets/images/dashboard-icon.svg";
import ActiveCustomersIcon from "assets/images/customers-active-icon.svg";
import LogoutIcon from "assets/images/logout-icon.svg";
import MapIcon from "assets/images/map-icon.svg";
import ProfileIcon from "assets/images/profile-Icon.svg";
import SearchIcon from "assets/images/search-icon.svg";
import NotificationIcon from "assets/images/notification-icon.svg";
import ActiveProfileIcon from "assets/images/profile-active-icon.svg";
import ActiveMapIcon from "assets/images/map-active-icon.svg";
import ActiveDashboardIcon from "assets/images/dashboard-active-icon.svg";
import CustomerIcon from "assets/images/customers-icon.svg";
import LogoSm from "assets/images/logo-sm.svg";
import CameraIcon from "assets/images/camera-icon.svg";
import BlueBriefcase from "assets/images/blue-briefcase.svg";
import Site from "assets/images/site-active.svg";
import SiteIcon from "assets/images/site.svg";
import ActiveSite from "assets/images/site-active.svg";
import DeleteIcon from "assets/images/delete-icon.png";
import EditIcon from "assets/images/edit-icon.png";
import UploadImgIcon from "assets/images/uploadImg-icon.svg";
import AddIcon from "assets/images/add-icon.svg";
import ArrowActive from "assets/images/arrow-active.svg";
import ArrowDisabled from "assets/images/arrow-disabled.svg";
import LeftArrowIcon from "assets/images/left-arrow-icon.svg";
import BtnClose from "assets/images/btn-close.svg";
import ArrowBack from "assets/images/arrow-back.svg";
import Intersect from "assets/images/intersect.svg";
import Alert from "assets/images/alert.svg";
import AlertIcon from "assets/images/alert-icon.svg";
import AlertActiveIcon from "assets/images/alert.svg";
import AlertHistory from "assets/images/alert-history-icon.svg";
import AlertHistoryActiveIcon from "assets/images/alert-history.svg";
import CCTV from "assets/images/cctv.svg";
import CCTVIcon from "assets/images/cctv-icon.svg";
import CCTVActiveIcon from "assets/images/cctv.svg";
import FloorManager from "assets/images/floor-manager.svg";
import FloorManagerIcon from "assets/images/floormanager-icon.svg";
import FloorManagerActiveIcon from "assets/images/floor-manager.svg";
import Supervisor from "assets/images/supervisor.svg";
import SupervisorIcon from "assets/images/supervisor-logo.svg";
import ActiveSupervisorIcon from "assets/images/supervisor.svg";
import CCTVSwitch from "assets/images/cctv-switch.svg";
import Fire from "assets/images/fire.svg";
import AreaActive from "assets/images/area-active.svg";
import Area from "assets/images/area.svg";
import SupportTicketIcon from "assets/images/support-ticket.svg";
import SupportTicketActiveIcon from "assets/images/support-ticket-active.svg";
import User from "assets/images/user.svg";
import SupportTicket from "assets/images/support_ticket.svg";
import BtnLink from "assets/images/btn-link.svg";
import AttachPin from "assets/images/attach_pin.svg";
import GlobalIcon from "assets/images/global-icon.svg";
import SettingIcon from "assets/images/settings-icon.svg";
import SettingActiveIcon from "assets/images/settings-active-icon.svg";

const appImages = {
     spalshImg,
     loginImg,
     EyeSlash,
     UserIcon,
     Eye,
     BrandImg,
     DashboardIcon,
     ActiveCustomersIcon,
     LogoutIcon,
     MapIcon,
     ProfileIcon,
     SearchIcon,
     NotificationIcon,
     ActiveProfileIcon,
     ActiveMapIcon,
     ActiveDashboardIcon,
     CustomerIcon,
     LogoSm,
     CameraIcon,
     BlueBriefcase,
     Site,
     DeleteIcon,
     EditIcon,
     UploadImgIcon,
     AddIcon,
     ArrowActive,
     ArrowDisabled,
     LeftArrowIcon,
     BtnClose,
     ArrowBack,
     Intersect,
     Alert,
     CCTV,
     FloorManager,
     FloorManagerIcon,
     FloorManagerActiveIcon,
     Supervisor,
     CCTVSwitch,
     Fire,
     ActiveSite,
     SiteIcon,
     AreaActive,
     Area,
     ActiveSupervisorIcon,
     SupervisorIcon,
     AlertIcon,
     AlertActiveIcon,
     AlertHistory,
     AlertHistoryActiveIcon,
     CCTVActiveIcon,
     CCTVIcon,
     SupportTicketIcon,
     SupportTicketActiveIcon,
     User,
     SupportTicket,
     BtnLink,
     AttachPin,
     GlobalIcon,
     SettingActiveIcon,
     SettingIcon
}

export default appImages;