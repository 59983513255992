import AlarmList from "components/alarms/list";
import EditSite from "components/site/edit";
import { lazy } from "react";
import { PrivateRoute } from "routes/privateRoute";
const Dashboard = lazy(() => import("components/dashboard/dashboard"));
const Area = lazy(() => import("components/area/area"));
const CCTV = lazy(() => import("components/cctv/cctv"));
const Supervisor = lazy(() => import("components/supervisor/supervisor"));
const SupervisorDetail = lazy(() => import("components/supervisor/supervisorDetail/supervisorDetail"));
const Map = lazy(() => import("components/map/map"));
const ProfileDetail = lazy(() => import("components/profileDetail/profileDetail"));
const SiteList = lazy(() => import("components/site/list"));
const FloorManager = lazy(() => import("components/floorManager/floorManager"));
const FloorManagerDetail = lazy(() => import("components/floorManager/floorManagerDetail"));
const SupportTicket = lazy(() => import("components/supportTicket/supportTicket"));
const Settings = lazy(() => import("components/settings/settings"));
const AlertHistory = lazy(() => import("components/alarms/history"));

const UserRoutes = [
  {
    path: "/:slug/dashboard",
    element: (<PrivateRoute component={Dashboard} permission="view_dashboard"/>),
  },
  {
     path: "/:slug/site",
     element: (<PrivateRoute component={SiteList} permission="view_sites" />),
  },
  {
     path: "/:slug/site/detail/:siteKey",
     element: (<PrivateRoute component={EditSite} permission="view_sites" />),
  },
  {
     path: "/:slug/cctv",
     element: (<PrivateRoute component={CCTV} permission="view_cctv" />),
   },
  {
     path: "/:slug/alarms",
     element: (<PrivateRoute component={AlarmList} permission="view_alarm" />),
   },
  {
    path: "/:slug/area",
    element: (
      <PrivateRoute component={Area} permission="view_area" />
    ),
  },
  {
    path: "/:slug/supervisor",
    element: (
      <PrivateRoute component={Supervisor} permission="view_supervisor" />
    ),
  },
  {
    path: "/:slug/alert-history",
    element: (
      <PrivateRoute component={AlertHistory} permission="view_supervisor" />
    ),
  },
  {
    path: "/:slug/supervisor/detail/:suepervisorKey",
    element: (
      <PrivateRoute component={SupervisorDetail} permission="view_supervisor" />
    ),
  }, 
  {
    path: "/:slug/floor-manager",
    element: (
      <PrivateRoute component={FloorManager} permission="view_floormanager" />
    ),
  },
  {
    path: "/:slug/floor-manager/detail/:floorManagerKey",
    element: (
      <PrivateRoute component={FloorManagerDetail} permission="view_floormanager" />
    ),
  }, 
  {
    path: "/:slug/profile",
    element: (
      <PrivateRoute component={ProfileDetail} permission="view_profile" />
    ),
  },
  {
    path: "/:slug/map",
    element: (
      <PrivateRoute component={Map} permission="view_map" />
    ),
  },
  {
     path: "/:slug/support-ticket",
     element: (
       <PrivateRoute component={SupportTicket} permission="view_support_ticket" />
     ),
   },
  {
     path: "/:slug/settings",
     element: (
       <PrivateRoute component={Settings} permission="view_settings" />
     ),
   },
];

export default UserRoutes;
